@mixin admin-sticky-fix( $offset: 0 ) {
  $narrow-offset: 46px;
  $wide-offset: 32px;
  @if $offset != 0 and type-of($offset) == 'number' {
    $narrow-offset: $narrow-offset + $offset;
    $wide-offset: $wide-offset + $offset;
  }
  .admin-bar & {
    top: $narrow-offset;
    @media screen and (min-width: 783px) {
      top: $wide-offset;
    }
  }
}