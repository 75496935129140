/*------------- #TABLE OF CONTENTS --------------*/

/*------------------------------------------------------------------

1. Blocks / Styles for Blocks
	1.1 Acordion
	1.2 Alerts
	1.3 Blogs
	1.4 Buttons
	1.5 Choose
	1.6 Clients
	1.7 Comments
	1.8 Conference Details
	1.9 Counters
	1.10 Counting Down
	1.11 Course Details
	1.12 Course Features
	1.13 Double Images
	1.14 Curriculum Event
	1.15 Curriculum List
	1.16 Dotted Map
	1.17 Events
	1.18 Forms
	1.19 Heading
	1.20 Info Box
	1.21 Jquery Typehead
	1.22 Module Image
	1.23 Navigation
	1.24 Nice Select
	1.25 Our Video
	1.26 Pagination
	1.27 Pagination Arrow
	1.28 Popup Search
	1.29 Pricing Tables
	1.30 Product Case
	1.31 Raiting Details
	1.32 Reviews
	1.33 Screenshots
	1.34 Send Message Popup
	1.35 Skew Rows
	1.36 Skills
	1.37 Sliders
	1.38 Smartphone Video
	1.39 Socials
	1.40 Sorting
	1.41 Subscribe Form
	1.42 Switcher
	1.43 Teammembers
	1.44 Testimonials
	1.45 Tripple Images
	1.46 Zoom Images
	1.47 Range sliders
2. Theme Styles / Main Theme Styles
	2.1 Fonts / Styles for fonts
	2.2 Footer
	2.3 Global
	2.4 Grid / Styles for modular grid
	2.5 Header
	2.6 Helper / Additional classes
	2.7 Magnific Popup
	2.8 Mixins
	2.9 Normalize / Makes browsers render all elements more consistently and in line with modern standards
	2.10 Page 404
	2.11 Primary Menu
	2.12 Stunning Header
	2.13 Top Bar
	2.14 Typography / Typography Rules
	2.15 Variables / Variables for styles
3. Widgets / Styles for all Widgets
	3.1 Widget About
	3.2 Widget Author
	3.3 Widget Category
	3.4 Widget Contacts
	3.5 Widget Events
	3.6 Widget Follow
	3.7 Widget Info
	3.8 Widget Latest Members
	3.9 Widget Latest News
	3.10 Widget List
	3.11 Widget Popular Products
	3.12 Widget Search
	3.13 Widget Tags



-------------------------------------------------------------------*/